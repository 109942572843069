<template>
    <tr v-if="newItemAction">
        <td colspan="100">
            <create-form-new-order-item @removeItem="removeItem(key)" ref="newOrderItem" :response="response" v-for="value,key in qty"
                            :key="key" :keyProp="key" :defaultValues="value" :scope="getScope(key)" v-if="value !== null"
                            :action="response" :new-item-action="newItemAction"></create-form-new-order-item>

            <tr class="text-center grid grid-cols-10">
                <td colspan="100" class="col-span-10">
                    <div class="grid grid-cols-3 p-3">
                        <custom-button @click.native="addNewItem()" data-cy="add-item" class="col-start-2 inline-flex justify-center">
                            + New Order Item
                        </custom-button>
                        <upload-items-from-csv @itemsImported="addImportedItems"></upload-items-from-csv>
                    </div>
                </td>
            </tr>
        </td>
    </tr>
</template>

<script>
    import UploadItemsFromCsv from "@/components/UploadItemsFromCsv.vue";
    import CreateFormNewOrderItem from "@/v3/components/CreateFormNewOrderItem.vue";
    import CustomButton from "@/v3/Buttons/CustomButton.vue";

    export default {
        inject: ["$validator"],
        components: {
            CreateFormNewOrderItem,
            UploadItemsFromCsv,
            CustomButton
        },
        props: {
            response: {
                type: Object
            },
            newItemAction:{
                type: Object,
                default: null
            }
        },
        data() {
            return {
                qty: []
            }
        },
        computed: {
        },
        methods: {
            addImportedItems(obj) {
                obj.forEach(object => {
                    this.qty.push(object);
                });
                this.$emit('change');
                this.$store.dispatch(
                    "setMessage",
                    "CSV imported, please check the rows & save the order to persist the changes."
                );
            },
            removeItem(key) {
                this.$set(this.qty, key, null);
            },
            async addNewItems(action) {
                let addNewItemPromises = [];
                if (this.$refs.newOrderItem) {
                    for (const { index,row } of this.$refs.newOrderItem.map((row, index) => ({ index,row }))){
                        console.log(index);
                       addNewItemPromises.push(await row.create(action).catch(error => {
                            throw {
                                error: error,
                                scope: this.getScope(index)
                            };
                        }));
                    }
                }
                return addNewItemPromises;
            },
            getScope(index) {
                return 'new-order-item-row-' + index;
            },
            addNewItem() {
                this.qty.push({});
                this.$emit('change');
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
