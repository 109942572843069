<template>
    <div class="cursor-default">
        <portal to="destination">
        <large-model-with2-buttons v-show="displayModal" :submitting-second="submittingModal" :title="null" :submitting="submittingSecondModal"
                    :confirmationString='null' second-button-title="View Order" first-button-title="Submit Order" buttonType="positive" additionalFirstButtonClass="px-8"
                    @removeFromDisplay="$emit('removeFromDisplay')" @firstPerform="perform('quote')" @secondPerform="perform('order')">
            <header-with-app-colors class="col-span-2" :collapsable="false" color="v3-green" color-opacity="20">
                <template v-slot:title>
                    {{ title }}
                </template>
            </header-with-app-colors>
            <new-order-items-pagination ref="orderItems" :create-item-action="createOrderItemAction" :collapsable="false" class="my-2" :key="$vnode.key">

            </new-order-items-pagination>

            <div v-show="errors.has('items')" class="text-red-600 text-sm">{{ errors.first('items') }}</div>

        </large-model-with2-buttons>
        </portal>
        <portal to="destination">
            <modal v-show="cannotSubmitModal" title="Cannot Submit" confirmation-string="Order Cannot be submitted without items" @perform="cannotSubmitModal = false" @removeFromDisplay="cannotSubmitModal = false" :showCancelButton="false">
            </modal>
        </portal>
    </div>
</template>

<script>
import SlideOver from "@/v2/components/SlideOver";
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";
import LargeModel from "@/v3/models/LargeModel.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";
import BlankHeader from "@/v3/components/headers/BlankHeader.vue";
import Header from "@/v2/components/Header.vue";
import CollapsableHeader from "@/v3/components/headers/CollapsableHeader.vue";
import Siren from "super-siren";
import {mapGetters} from "vuex";
import CustomButton from "@/v3/Buttons/CustomButton.vue";
import SplitOrderForm from "@/v3/components/forms/SplitOrderForm.vue";
import GoodsInNoteForm from "@/v3/components/forms/GoodsInNoteForm.vue";
import OrderItemsPagination from "@/v3/components/pagination/OrderItemsPagination.vue";
import NewOrderItemsPagination from "@/v3/components/pagination/NewOrderItemsPagination.vue";
import LargeModelWith2Buttons from "@/v3/models/LargeModelWith2Buttons.vue";
import HeaderWithAppColors from "@/v3/components/headers/HeaderWithAppColors.vue";
import Modal from "@/v3/components/modals/Modal.vue"
let follow = Siren.Helper.follow;
export default {

    inject: ["$validator"],
    name: "AddItemsToOrderForm",
    data() {
        return {
            submittingModal: false,
            clientId: null,
            projectId: null,
            deliveryMethod: 'delivery',
            submittingSecondModal: false,
            cannotSubmitModal: false
        }
    },
    components: {
        HeaderWithAppColors,
        LargeModelWith2Buttons,
        NewOrderItemsPagination,
        OrderItemsPagination, GoodsInNoteForm, SplitOrderForm,
        CustomButton,
        CollapsableHeader,
        Header,
        BlankHeader,
        PropertyOrField,
        LargeModel,
        SlideOver,
        'a-button': Button,
        Modal
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        buttonType:{
            type: String,
            default: 'primary'
        },
      isButton:{
          type: Boolean,
          default: true
      },
        createOrderItemAction:{
            type: Object,
            default: null
        },
        displayModal:{
            type: Boolean,
            default: false
        },
        order:{
            type: Object,
            default: null
        }
    },
    mounted(){
        this.$emit('loaded');
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken"
        }),
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            if(payload['client_id'] === undefined)
            {
                Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
                Siren.get(process.env.VUE_APP_API_URL + "/api/")
                    .then(
                        follow("current-user")
                    )
                    .then(res => {
                        this.clientId = res.body.properties.get("client_id");
                    }, this);
            }
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform(returnValue) {
            if(!this.submittingModal && !this.submittingSecondModal) {
                if(returnValue === 'order'){
                    this.submittingModal = true;
                }else if(returnValue === 'quote'){
                    this.submittingSecondModal = true;
                }
                this.$refs.orderItems.updateItems(this.action).then(res => {
                    let selfLink = this.order.body.links.filter(link => link.rels.contains('self')).first().href;
                    let parts = selfLink.split('/api');
                    Siren.Client.addHeader(
                        "Authorization",
                        `Bearer ${this.accessToken}`
                    );
                    if(returnValue === 'order') {
                        this.$store.dispatch(
                            "setMessage",
                            'The items were added successfully.'
                        );
                        Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                            this.submittingModal = false;
                            this.$emit('viewOrder', response);
                            this.$emit('removeFromDisplay');
                        }).catch(function () {

                        });
                    }else if(returnValue === 'quote'){
                        Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                            let getQuoteAction = response.body.actions.filter(action => action.name === 'mark-as-ready-for-quote').first();

                            if(getQuoteAction) {
                                getQuoteAction.perform().then(response2 => {
                                    this.$store.dispatch(
                                        "setMessage",
                                        'The items were added and order sent for quote successfully.'
                                    );
                                    Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                                        this.submittingSecondModal = false;
                                        this.$emit('viewOrder', response);
                                        this.$emit('removeFromDisplay');
                                    }).catch(function () {

                                    });
                                })
                            }else{
                                this.cannotSubmitModal = true;
                                this.submittingSecondModal = false;
                                this.submittingModal = false;
                            }
                        })

                    }

                }).catch(function (error) {
                    console.log(error.error);
                    this.submittingModal = false;
                    this.submittingSecondModal = false;
                    if(error.error.status !== 422) {

                        alert('There was an error processing your request.' + error);
                    }
                    this.$refs.orderItems.setErrors(JSON.parse(error.error.response.text), error.scope);

                }.bind(this));
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        isActionRequired(fieldId){
            if (!this.action) {
                return false;
            }
            let field = this.action.fields.filter(field => field.name === fieldId).first();
            if(field){
                if(field.validation != null) {
                    return field.validation.includes('required')
                }
            }
            return false;
        },
        hasField(fieldId){
            if (!this.action) {
                return false;
            }
            return this.action.fields.filter(field => field.name === fieldId).first();
        }
    },
}
</script>

<style scoped>

</style>
