<template>
  <div class="py-2 px-4 sm:px-6 rounded-full text-v3-gray-800 dark:text-v3-gray-200"
       :class="{[headerColourClass]: headerColourClass && !color, [`bg-${color}`]: color, [`bg-opacity-${colorOpacity}`]: colorOpacity}" @click="toggleOpen">
    <span>
        <span class="font-bold flex justify-center">
            <slot name="title">
                {{ title }}
            </slot>
        </span>
    </span>

  </div>
</template>
<script>
export default {
  name: 'header-with-app-colors',
    props:{
      collapsable:{
          type: Boolean,
          default: true
      },
        open:{
          type: Boolean,
            default: false
        },
        headerColourClass:{
            type: String,
            default: 'bg-v3-base-200 dark:bg-v3-base-800'
        },
        title:{
          type: String,
            default: 'Details'
        },
        color:{
          type: String,
            default: null
        },
        colorOpacity:{
          type: String,
            default: null
        }
    },
    methods:{
      toggleOpen(){
          this.$emit('toggleOpen');
      }
    }
}
</script>
