<template>
        <main-template>
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                 background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>
                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>Procure</template>
            <template v-slot="slotProps">
            <fetch-siren-root ref="sirenRoot" :linkName='linkName' :link="link">
                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">
                        <div class="flex w-full overflow-hidden">
                            <div class="p-2 h-full">
                                <procurement-navigation :links="slotProps.links"></procurement-navigation>
                            </div>
                            <div class="flex w-full h-full p-2 overflow-hidden">
                                <div class="rounded-xl bg-v3-white-100 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-hidden" :class="{'grid grid-cols-3': (details || loadingDetails) && !hideTable}">
                                    <loading v-if="!response"></loading>
                                    <template v-else>
                                        <div class="p-2 overflow-y-auto h-full" :class="{'hidden': hideTable}">
                                            <procurement-pagination ref="orderList" :title="title" :response="response" :orders="getOrders(response)" @setDetails="showDetails" @setLoading="setLoading" @altViewClicked="altViewClicked">
                                                <template v-slot:additional-buttons>
                                                    <create-order-form
                                                        v-if="response.body.actions.filter(action => action.name === 'create-new-order').first()"
                                                        title="Create Order"
                                                        :action="response.body.actions.filter(action => action.name === 'create-new-order').first()"
                                                        :redirect-on-success="false"
                                                        @viewOrder="viewOrder"
                                                        ref="createOrderForm"
                                                        @loaded="isCreateOrder"
                                                        @addItems="addItems"
                                                        @updateRefreshKey="updateRefreshKey"
                                                        :key="'order_'+refreshKey"
                                                        :keys="'order2_'+refreshKey"
                                                        :createOrderItemAction="response.body.actions.filter(action=>action.name === 'add-new-item').first()"
                                                        ></create-order-form>
                                                    <add-items-to-order-form
                                                        title="Add Items To Order"
                                                        :action="addItemsToOrderAction"
                                                        :redirect-on-success="false"
                                                        @viewOrder="viewOrder"
                                                        ref="createOrderForm"
                                                        @loaded="isCreateOrder"
                                                        :displayModal="displayAddItems"
                                                        @removeFromDisplay="removeAddItemsFromDisplay"
                                                        :order="createdOrder"
                                                        :key="'item_'+refreshKey"
                                                        :createOrderItemAction="response.body.actions.filter(action=>action.name === 'add-new-item').first()">

                                                    </add-items-to-order-form>
                                                </template>
                                            </procurement-pagination>
                                        </div>
                                        <SingleOrderDetails ref="orderDetails" :details="details" :loadingDetails="loadingDetails" :hideTable="hideTable" @setLoading="setLoading" @getDetails="getDetails" @hideTable="toggleHideTable"/>
                                    </template>
                                </div>
                            </div>
                        </div>
                </div>
            </fetch-siren-root>
            </template>
        </main-template>
</template>

<script>
import AddItemsToOrderForm from "@/v3/components/forms/AddItemsToOrderForm.vue";
import CreateOrderForm from "@/v3/components/forms/CreateOrderForm.vue";
import FetchSirenRoot from '@/components/FetchSirenRoot';
import Loading from '@/components/Loading';
import MainTemplate from '@/v3/templates/MainTemplate';
import {mapGetters} from "vuex";
import ProcurementNavigation from "@/v3/components/navigation/ProcurementNavigation.vue";
import ProcurementPagination from "@/v3/components/pagination/OrdersPagination.vue";
import SingleOrderDetails from "@/v3/pages/SingleOrderDetails.vue";
import Siren from "super-siren";

export default {
    inject: ["$validator"],
    components: {
        CreateOrderForm,
        SingleOrderDetails,
        ProcurementNavigation,
        ProcurementPagination,
        FetchSirenRoot,
        Loading,
        MainTemplate,
        AddItemsToOrderForm
    },
    data() {
        return {
            slotProps: null,
            users: null,
            details: null,
            detailsOpen: false,
            loadingDetails: false,
            orders: null,
            hideTable: false,
            refreshKey: 0,
            addItemsToOrderAction: null,
            displayAddItems: false,
            createdOrder: null
        }
    },
    props:{
        title:{
            type: String,
            default: 'Orders'
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser"
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        }

    },
    mounted() {
        let follow = Siren.Helper.follow;
    },
    methods: {
        getOrders(response) {
            if (!response) {
                return null;
            }
            return response.body.entities.filter(entity => entity.rels.contains('order'));
        },
        getAllOrders(){
            this.$refs.sirenRoot.load();
        },
        showDetails(details){
            this.$setLaravelValidationErrorsFromResponse('');
            this.$refs.orderList.detailsOpen = true;
            this.details = details;
            this.loadingDetails = false;
        },
        setLoading(){
            this.$refs.orderDetails.editing = false;
            this.$refs.orderDetails.editingOrderItems = false;
            this.$refs.orderDetails.tabOpen = 'order-list';
            this.details = null;
            this.loadingDetails = true;
        },
        getDetails(entity){
            this.$refs.orderList.getDetails(entity);
        },
        toggleHideTable(){
            this.hideTable = !this.hideTable;
        },
        altViewClicked(){
            this.details = null;
            this.$refs.orderList.detailsOpen = false;
            this.getAllOrders();
        },
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=procure_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=procure_light"
        },
        viewOrder(res){
            this.updateRefreshKey();
            this.getAllOrders();
            this.showDetails(res.body);
        },
        addItems(res){
            this.displayAddItems = true;
            this.addItemsToOrderAction = res.body.actions.filter(action =>action.name === 'add-new-item').first();
            this.createdOrder = res;
            this.updateRefreshKey();
        },
        removeAddItemsFromDisplay(){
            this.displayAddItems = false;
            this.addItemsToOrderAction = null;
            this.createdOrder = null;
            this.updateRefreshKey();
        },
        isCreateOrder(){
            if(this.$route.hash === '#new-order'){
                this.$refs.createOrderForm.displayModal = true;
                this.$router.push('/orders');
            }
        },
        updateRefreshKey()
        {
            this.refreshKey++;
            console.log(this.refreshKey);
        }

    }
}
</script>

