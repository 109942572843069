<template>
    <tr class="grid grid-cols-16">
        <template ref="newItem">

            <td class="col-span-7 py-3.5 pl-4 pr-3 whitespace-normal border-b border-v3-gray-900 dark:border-white border-opacity-10 dark:border-opacity-10 text-sm leading-5 text-gray-500 align-top">
                <property-or-autocomplete-field ref="description"  :scope="scope"
                                                 :action="newItemAction"
                                                fieldId="description" propertyKey="description"
                                                v-model="newItemActionData['description']"
                                    :showLabel="false"></property-or-autocomplete-field>
            </td>
            <td class="col-span-2 px-3 py-4 whitespace-normal border-b border-v3-gray-900 dark:border-white border-opacity-10 dark:border-opacity-10 text-sm leading-5 text-gray-500 align-top">
                <property-or-autocomplete-field ref="code" :scope="scope" :action="newItemAction" fieldId="code"
                                                propertyKey="code"
                                                v-model="newItemActionData['code']"
                                    :showLabel="false"></property-or-autocomplete-field>
            </td>
            <td class="col-span-2 px-3 py-4 whitespace-normal border-b border-v3-gray-900 dark:border-white border-opacity-10 dark:border-opacity-10 text-sm leading-5 text-gray-500 align-top">
                <property-or-field ref="next" :scope="scope" :action="newItemAction" fieldId="due_date"
                                   propertyKey="due_date" v-model="newItemActionData['due_date']"
                                    :showLabel="false"></property-or-field>
            </td>
            <td class="col-span-2 px-3 py-4 whitespace-normal border-b border-v3-gray-900 dark:border-white border-opacity-10 dark:border-opacity-10 text-sm leading-5 text-gray-500 align-top">
                <property-or-field :scope="scope" :action="newItemAction" fieldId="quantity" propertyKey="quantity"
                                   v-model="newItemActionData['quantity']"
                                    :showLabel="false"></property-or-field>
            </td>
            <td class="col-span-2 px-3 py-4 whitespace-normal border-b border-v3-gray-900 dark:border-white border-opacity-10 dark:border-opacity-10 text-sm leading-5 text-gray-500 align-top">
                <property-or-field :scope="scope" :action="newItemAction" fieldId="unit" propertyKey="unit"
                                   v-model="newItemActionData['unit']"
                                    :showLabel="false"></property-or-field>
            </td>
            <td class="px-3 py-4 whitespace-normal border-b border-v3-gray-900 dark:border-white border-opacity-10 dark:border-opacity-10 text-sm leading-5 text-gray-500 align-top">
                <span title="remove" class="cursor-pointer font-normal underline text-gray-400" @click="removeItem();">
                    <svg class="text-v3-gray-600 dark:text-v3-base-300 fill-current h-6 w-6 cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z" fill="currentColor"/>
                    </svg>
                </span>
            </td>
        </template>
    </tr>
</template>

<script>
    import PropertyOrField from "@/components/PropertyOrField.vue";
    import PropertyOrAutocompleteField from "@/components/PropertyOrAutocompleteField.vue";
    import OrderItemsUpdate from "@/mixins/orderItemsUpdate";
    import axios from "axios";

    export default {
        inject: ["$validator"],
        mixins: [OrderItemsUpdate],
        data() {
            return {
                isSuccessfullyProcessed: false
            }
        },
        components: {
            PropertyOrField,
            PropertyOrAutocompleteField
        },
        props: {
            response: {
                type: Object,
            },
            scope: {
                type: String
            },
            keyProp: {
                type: Number
            },
            defaultValues: {
                type: Object,
                default: {}
            },
            newItemAction:{
                type: Object,
                default: null
            }
        },
        computed: {
            searchAction() {
                return this.response.actions.filter(action => action.name === 'search-products').first();
            },
            newItemActionData() {
                if (!this.newItemAction.fields) {
                    return {};
                }
                var payload = {};
                this.newItemAction.fields.forEach(function (field) {
                    if (this.defaultValues[field.name]) {
                        payload[field.name] = this.defaultValues[field.name]
                    } else if (field.value !== undefined) {
                        payload[field.name] = field.value;
                    }
                }, this);
                return payload;
            },
        },
        methods: {
            async create(action) {
                if (this.isSuccessfullyProcessed) {
                    return Promise.resolve();
                }
                return await action.perform(this.newItemActionData).then(res => {
                    this.isSuccessfullyProcessed = true;
                });
            },
            removeItem() {
                this.$emit('removeItem', this.keyProp);
            },
            descriptionSuggestionChanged(value)
            {
                let id = this.updateCode(value);
                if(id) {
                    this.newItemActionData['product_id'] = id;
                }
            },
            codeSuggestionChanged(value)
            {
                let id = this.updateDescription(value);
                if(id) {
                    this.newItemActionData['product_id'] = id;
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
